import React from 'react'
import classNames from 'classnames'

import styles from '@components/blocks/header_block.module.scss'
import ImageAsset from '@components/image_asset'

const HeaderBlock = React.forwardRef(({ fields }, _) => {
  const isImageTopPosition = fields.imagePosition === 'Top'

  const getImage = () => {
    const imageField = fields.image

    if (imageField === undefined) {
      return null
    }

    if (isImageTopPosition) {
      const backgroundImageStyle = {
        background: `url(${imageField.fields.file.url}?fm=jpg&q=85&h=160)`,
      }
      return <div style={backgroundImageStyle} className={styles['header_block__image--top']} />
    }

    const imagePositionClass = (imagePosition => {
      switch (imagePosition) {
        case 'Bottom Right':
          return styles['header_block__image--bottom-right']
        default:
          return styles['header_block__image--center-right']
      }
    })(fields.imagePosition)

    return (
      <ImageAsset
        className={classNames(styles.header_block__image, imagePositionClass)}
        asset={imageField}
      />
    )
  }

  const subtitle = fields.subtitle

  const backgroundColorClass = (backgroundColor => {
    switch (backgroundColor) {
      case 'Blue':
        return styles['header_block--blue']
      case 'Red':
        return styles['header_block--red']
      case 'Green':
        return styles['header_block--green']
      case 'Purple':
        return styles['header_block--purple']
      case 'Light Blue':
        return styles['header_block--light_blue']
      case 'Tan':
        return styles['header_block--tan']
      case 'White':
        return styles['header_block--white']
      default:
        return null
    }
  })(fields.backgroundColor)

  const contentClasses = [styles.header_block__content]
  if (isImageTopPosition) {
    contentClasses.push(styles['header_block__content--short'])
  }

  return (
    <div className={classNames(styles.header_block, backgroundColorClass)}>
      {isImageTopPosition && getImage()}
      <div className={classNames(contentClasses)}>
        <h1 className={styles.header_block__title}>{fields.title}</h1>
        {subtitle && <p className={styles.header_block__subtitle}>{subtitle}</p>}
        {!isImageTopPosition && getImage()}
      </div>
    </div>
  )
})

export default HeaderBlock
